<script setup>
import { storeToRefs } from 'pinia';
import { useCenterStore } from '~/stores/centerDetails';
import { useSideBar } from '~/stores/sideBar';
import { useMessageStore } from '~/stores/messages';
import { trackPageLoad } from '~/composables/useTracking';

// Get sideBarStatus + toggle function
const sideBarStore = useSideBar();
const { toggleSideBar, toggleSideBarOverlay } = sideBarStore;

// Get center details
const { centerUid, centerName, centerDetails } = storeToRefs(useCenterStore());

// Fetch messages
const messagesStore = useMessageStore();
const { messages: staticKeys } = storeToRefs(messagesStore);

// Center has opening hours
const hasOpeningHours = computed(() => centerDetails.value?.hours?.weekly.length > 0);

// Center has contact items
const hasContacts = computed(() => Object.keys(centerDetails.value?.contacts).length > 0);

// Tracking
trackPageLoad('Center_Details', {
  center_id: centerUid.value,
  center_name: centerName.value
});

const openCenterSelector = () => {
  // Close Sidebar
  toggleSideBarOverlay({ open: false }, false)

  // Open sidebar with center selector
  toggleSideBar({
    open: true,
    content: 'centerSelector'
  })
}

// Button config
const buttonConfig = {
  buttonType: 'primary',
  type: 'div',
  active: true,
  large: true,
  fn: () => openCenterSelector()
};
</script>

<template>
    <SideBarWrapper class="center-detail-sidebar">
        <SideBarHeader>
            <SideBarMainHeadline :text="staticKeys?.centerselector_center_headline__markdown" />
        </SideBarHeader>

        <LoadingSpinner v-if="! centerDetails" class="sidebar-loader" />

        <template v-else>
            <ul class="center-detail-list">
                <CenterListItem :center="centerDetails" class="center-list-item-selected" />
            </ul>

            <UiButton :config="buttonConfig">
                {{ staticKeys?.centerselector_change_btn_label }}
            </UiButton>

            <h3 class="sidebar-headline">{{ staticKeys?.center_openinghours_headline }}</h3>

            <div v-if="hasOpeningHours" class="opening-hours-container">
                <OpeningHours :hours="centerDetails?.hours?.weekly" />
            </div>

            <h3 class="sidebar-headline">{{ staticKeys?.center_route_headline }}</h3>

            <RouteLink
                v-if="centerDetails.address?.googleMaps"
                :route="{
                    link: centerDetails.address.googleMaps,
                    address: centerDetails.address?.plain,
                    label: staticKeys?.center_route_button_label
                }"
            />

            <h3 class="sidebar-headline">{{ staticKeys?.center_contact_headline }}</h3>

            <CenterDetailContactList v-if="hasContacts" :contacts="centerDetails?.contacts" />
        </template>
    </SideBarWrapper>
</template>

<style lang="scss">
.center-detail-sidebar {
  min-height: 100%;

  .sidebar-headline {
    @include sidebar-headline;

    margin: 70px 0 15px;

    &:first-of-type {
      margin-top: 70px;
    }
  }
}

.center-detail-list {
  margin: 0 0 30px;
  padding: 0;
  list-style-type: none;
}

.opening-hours-container {
  padding: 32px;
  border-radius: 24px;
  background: $center-opening-hours-container-bg;
}
</style>
