<script setup>
import { storeToRefs } from 'pinia';
import { useCenterStore } from '~/stores/centerDetails';
import Locator from '@/assets/icons/locator.svg';
import Right from '@/assets/icons/right.svg';
import { trackUserClick } from '~/composables/useTracking';

defineProps({
    route: {
        type: Object,
        default: () => {
        }
    }
});

// Get center details
const { centerUid, centerName } = storeToRefs(useCenterStore());

// Tracking
const trackClicks = (type) => {
    trackUserClick('Center_Contact_Click', {
        center_id: centerUid.value,
        center_name: centerName.value,
        contact_type: type,
        screen_name: 'Center_Details'
    });
}
</script>

<template>
    <a
        :href="route?.link"
        :title="route?.label"
        class="route-link-wrapper"
        target="_blank"
        rel="noopener noreferrer"
        @click="trackClicks('route')"
    >
        <Locator class="route-link-locator-icon" />
        <address>{{ route?.address }}</address>
        <Right class="route-link-arrow-icon" />
    </a>
</template>

<style lang="scss">
.route-link-wrapper {
    padding: 15px 20px;
    border-radius: 24px;
    background: $route-link-wrapper-bg;
    text-decoration: none;
    color: inherit;
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    flex-flow: row wrap;
    justify-content: space-between;
    transition: background-color 400ms ease-in-out;

    &:hover {
        background: $route-link-wrapper-bg-hover;
    }

    address {
        width: calc(100% - 60px);
        font-style: normal;
        cursor: pointer;

        @include body1-medium;

        line-height: 1.3;
    }
}

.route-link-locator-icon {
    width: 18px;
    height: 24px;
}

.route-link-arrow-icon {
    width: 8px;
    height: 14px;
}
</style>
