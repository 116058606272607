<script setup>
import { storeToRefs } from 'pinia';
import { useCenterStore } from '~/stores/centerDetails';
import { useMessageStore } from '~/stores/messages';
import { trackUserClick } from '~/composables/useTracking';
import { emailLink, phoneLink } from '~/utils/formatters';
import SocialWebsite from '@/assets/icons/social_website_outline.svg';
import SocialPhone from '@/assets/icons/social_phone_outline.svg';
import SocialEmail from '@/assets/icons/social_email_outline.svg';
import SocialInstagram from '@/assets/icons/social_instagram_outline.svg';
import SocialFacebook from '@/assets/icons/social_facebook_outline.svg';

// Contact props
const props = defineProps({
    contacts: {
        type: Object,
        default: () => {
        }
    }
});

// Get center details
const { centerUid, centerName } = storeToRefs(useCenterStore());

// Fetch messages
const messagesStore = useMessageStore();
const { messages: staticKeys } = storeToRefs(messagesStore);

// Get email link
const getEmailLink = computed(() => emailLink(props.contacts?.email));

// Get phone link
const getPhoneLink = computed(() => phoneLink(props.contacts?.phone));

// Tracking
const trackClicks = (type) => {
    trackUserClick('Center_Contact_Click', {
        center_id: centerUid.value,
        center_name: centerName.value,
        contact_type: type,
        screen_name: 'Center_Details'
    });
}
</script>

<template>
    <div class="contacts-container">
        <table class="contact-table">
            <tbody>
                <tr v-if="getPhoneLink">
                    <td>
                        <SocialPhone />
                    </td>
                    <td>
                        <a
                            :href="getPhoneLink.link"
                            target="_blank"
                            rel="noopener noreferrer"
                            @click="trackClicks('phone')"
                        >
                            {{ getPhoneLink.number }}
                        </a>
                    </td>
                </tr>
                <tr v-if="getEmailLink">
                    <td>
                        <SocialEmail />
                    </td>
                    <td>
                        <a
                            :href="getEmailLink.link"
                            target="_blank"
                            rel="noopener noreferrer"
                            :title="contacts?.email"
                            @click="trackClicks('email')"
                        >
                            {{ getEmailLink.email }}
                        </a>
                    </td>
                </tr>
                <tr v-if="contacts?.website">
                    <td>
                        <SocialWebsite />
                    </td>
                    <td>
                        <a
                            :href="contacts.website"
                            rel="noopener noreferrer"
                            target="_blank"
                            :title="contacts.website"
                            @click="trackClicks('website')"
                        >
                            {{ contacts.website }}
                        </a>
                    </td>
                </tr>
                <tr v-if="contacts?.instagram">
                    <td>
                        <SocialInstagram />
                    </td>
                    <td>
                        <a
                            :href="contacts.instagram"
                            rel="noopener noreferrer"
                            target="_blank"
                            :title="contacts.instagram"
                            @click="trackClicks('instagram')"
                        >
                            {{ staticKeys?.center_socialLinks_instagram }}
                        </a>
                    </td>
                </tr>
                <tr v-if="contacts?.facebook">
                    <td>
                        <SocialFacebook />
                    </td>
                    <td>
                        <a
                            :href="contacts.facebook"
                            rel="noopener noreferrer"
                            target="_blank"
                            :title="contacts.facebook"
                            @click="trackClicks('facebook')"
                        >
                            {{ staticKeys?.center_socialLinks_facebook }}
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<style lang="scss">
.contacts-container {
    padding: 25px 32px;
    border-radius: 24px;
    background: $contacts-container-bg;
}

.contact-table {
    @include body2-medium;

    width: 100%;

    td {
        padding: 7px 0;
        vertical-align: middle;

        &:first-child {
            padding: 7px 20px 7px 0;
            white-space: nowrap;
            width: 1px;
        }

        &:last-child {
            width: 100%;
        }
    }

    a {
        text-decoration: none;
        color: inherit;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: calc(100vw - 172px);

        @include bp(500px) {
            max-width: 224px;
        }

        @include bp(medium1) {
            max-width: 224px;
        }

        &:hover {
            text-decoration: underline;
        }
    }
}
</style>
